import $ from "jquery";

(async () => {
  const ACCORDION_AUTO_CLOSE = $(".menu").data("accordion-auto-close");
  const BREAKPOINT = 768;

  // Utility functions
  const calculateScrollBarWidth = () => window.innerWidth - document.documentElement.clientWidth;

  const updateScrollBarWidth = () => {
    const scrollBarWidth = calculateScrollBarWidth();
    $("body").css("--sb-width", `${scrollBarWidth}px`);
  };

  const updateTransform = ($el, transformValue) => {
    if ($el.length) {
      $el.css("transform", transformValue);
    }
  };

  const getTransformValueByBurgerPlacement = (isBurgerPlacedRight, isVisible) =>
    isVisible ? "translateX(0)" : isBurgerPlacedRight ? "translateX(100%)" : "translateX(-100%)";

  const closeAccordionSubcategories = () => {
    $(".js-subcategories-accordion-toggle").each(function () {
      const $subcategory = $(this).closest(".item").find(".subcategories");
      if ($subcategory.length) {
        $subcategory.hide();
        $(this).removeClass("expand");
      }
    });
  };

  const toggleAccordionSubcategory = ($toggle) => {
    const $subcategory = $toggle.closest(".item").find(".subcategories").first();
    const isOpen = $subcategory.is(":visible");

    if (ACCORDION_AUTO_CLOSE) closeAccordionSubcategories();

    if ($subcategory.length) {
      $subcategory.toggle(!isOpen);
      $toggle.toggleClass("expand", !isOpen);
    }
  };

  const applySubcategoryTransform = (transformValue) => {
    $(".js-subcategories-reveal-target").each(function () {
      const $target = $(this);
      const $parentItem = $target.closest(".item");
      if ($parentItem.find(".js-subcategories-reveal-open").length) {
        $target.css("transform", transformValue);
      }
    });
  };

  const closeMenu = () => {
    const $menuToggle = $(".js-menu-toggle");
    const isBurgerPlacedRight = $menuToggle.hasClass("js-burger-placed--end");
    const transformValue = getTransformValueByBurgerPlacement(isBurgerPlacedRight, false);
    const $menu = $(".menu");

    if ($menu.length) {
      updateTransform($menu, transformValue);
      $menu.removeClass("menu--open");
    }

    $("body").removeClass("no-scroll");

    if ($(window).width() < BREAKPOINT) {
      setTimeout(() => applySubcategoryTransform(transformValue), 600);
    }

    closeAccordionSubcategories();
  };

  const toggleMenu = ($el, show) => {
    $el.on("click", function (event) {
      event.stopPropagation();
      const isBurgerPlacedRight = $el.hasClass("js-burger-placed--end");
      const transformValue = getTransformValueByBurgerPlacement(isBurgerPlacedRight, show);
      const $menu = $(".menu");

      if ($menu.length) {
        updateTransform($menu, transformValue);

        if (show) {
          $menu.addClass("menu--open");
          $("body").addClass("no-scroll");
        } else {
          $menu.removeClass("menu--open");
          $("body").removeClass("no-scroll");

          if ($(window).width() < BREAKPOINT) {
            setTimeout(() => applySubcategoryTransform(transformValue), 600);
          }

          closeAccordionSubcategories();
        }
      }
    });
  };

  const handleResize = () => {
    updateScrollBarWidth();
    $(".js-subcategories-reveal-target").css("transform", "");

    if ($(window).width() < BREAKPOINT) {
      closeAccordionSubcategories();
    }
  };

  // Initial setup
  updateScrollBarWidth();
  toggleMenu($(".js-menu-toggle"), true);
  toggleMenu($(".js-menu-close"), false);

  $(window).resize(handleResize);

  // Event listeners
  $(document).on("click", ".js-subcategories-accordion-toggle", function (event) {
    event.stopPropagation();
    toggleAccordionSubcategory($(this));
  });

  $(".js-subcategories-reveal-open").on("click", function (e) {
    if ($(window).width() < BREAKPOINT) {
      e.preventDefault();
      const $target = $(this).closest(".item").find(".js-subcategories-reveal-target");
      $target.css("transform", "translateX(0)");
    }
  });

  $(".js-subcategories-reveal-close").on("click", function (event) {
    event.stopPropagation();
    if ($(window).width() < BREAKPOINT) {
      const $target = $(this).closest(".js-subcategories-reveal-target");
      const $menuToggle = $(".js-menu-toggle");
      const isBurgerPlacedRight = $menuToggle.hasClass("js-burger-placed--end");
      const transformValue = getTransformValueByBurgerPlacement(isBurgerPlacedRight, false);
      $target.css("transform", transformValue);
    }
    closeAccordionSubcategories();
  });

  $(document).on("click", (event) => {
    if (
      !$(event.target).closest(
        ".menu, .js-menu-toggle, .js-subcategories-reveal-target, .js-subcategories-reveal-close",
      ).length
    ) {
      closeMenu();
    }
  });
})();
